<template>
  <div>
    <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)" class="flex-def"
         style="width: 100%;min-height: 40rem">
      <el-row v-if="mode==='list'" :gutter="40" style="width: 100%">
        <el-col v-for="(item,key) in list" :key="key" :span="6">
          <div class="y-addons-item flex-def flex-cCenter">
            <i class="el-icon-s-check" style="font-size: 2rem;color: #0069ba"></i>
            <div class="flex-def flex-cCenter flex-zBetween" style="margin-left: .5rem;width: calc(100% - 2rem)">
              <div style="font-size: 1rem;">代理商</div>
              <el-button @click="clickThis(item)" size="mini">进入管理</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <div v-if="mode==='empty'" class="flex-zCenter flex-cCenter flex-def"
           style="height: 20rem;width: 100%;font-size: 2rem;font-weight: 600;color: #e0dddd">
        暂无
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "panel",
  data() {
    return {
      list: [],
      loading: false,
      mode: ""
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    clickThis(item) {
      this.$router.push({
        name:item.page,
        params:this.$route.params,
      })
    },
    load() {
      this.loading = true;
      this.$api.role.list().then(res => {
        this.loading = false;
        this.list = res;
        if (res) {
          this.mode = "list";
        } else {
          this.mode = "empty"
        }
      })
    },
  }
}
</script>

<style scoped>
.y-addons-item {
  background-color: #ededed;
  padding: .5rem;
  width: 100%;
  margin-bottom: .5rem;
  border-radius: .5rem;
}

</style>